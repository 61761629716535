<template lang="pug">
div(v-if="$store.state.app.accessSySafra")
  v-container(fluid)
    DataFilter(:filterProps="filters", :items="dataStatus", :paginate="false")
      template(v-slot:actions)
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2(
              color="primary",
              v-on="on",
              @click="getClientes()",
              fab,
              small
            )
              v-icon(small) fa-solid fa-rotate-right
          span Atualizar

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn(
              color="primary",
              v-on="on",
              to="/sysafra/sysbackup/solicitacoes",
              fab,
              small
            )
              v-icon(small) mdi-page-next-outline
          span Solicitações de backup

      template(v-slot:default="{ items }")
        v-data-table.monitorTable(
          dense,
          item-key="idcliente",
          :headers="dataHeaders",
          :items="items",
          :expanded.sync="expandedHistory",
          :single-expand="true",
          :footer-props="footprops",
          show-expand
        )
          template(v-slot:item.statusserver="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-chip(
                  v-on="on",
                  v-bind="attrs",
                  :color="item.statusserver ? 'success' : 'error'",
                  small
                )
              span {{ item.statusserver ? "Conectado" : "Desconectado" }}

          template(v-slot:item.status="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-chip(
                  v-on="on",
                  v-bind="attrs",
                  :color="setStatus(item.status).color",
                  small
                )
              span {{ setStatus(item.status).x.substring(0, setStatus(item.status).x.length - 1) }}

          template(v-slot:item.backupAnterior="{ item }")
            v-row(v-if="item.backups.length > 1", no-gutters, align="center")
              div(style="width: 140px") {{ new Date(item.backups[1].datahorainicio).toLocaleString() }}
              div(style="width: 210px")
                v-btn(
                  outlined,
                  width="24",
                  height="24",
                  icon,
                  :color="getColorStatus(item.backups[1].status)",
                  @click="getDetailsBackup(item.backups[1].id)"
                )
                  v-icon mdi-information
                v-chip.ml-2(
                  :color="getColorStatus(item.backups[1].status)",
                  small,
                  @click="getDetailsBackup(item.backups[1].id)"
                ) {{ item.backups[1].log }}

          template(v-slot:item.backupAtual="{ item }")
            v-row(v-if="item.backups.length > 0", no-gutters, align="center")
              div(style="width: 140px") {{ new Date(item.backups[0].datahorainicio).toLocaleString() }}
              div(style="width: 210px")
                v-btn(
                  outlined,
                  width="24",
                  height="24",
                  icon,
                  :color="getColorStatus(item.backups[0].status)",
                  @click="getDetailsBackup(item.backups[0].id)"
                )
                  v-icon mdi-information
                v-chip.ml-2(
                  :color="getColorStatus(item.backups[0].status)",
                  small,
                  @click="getDetailsBackup(item.backups[0].id)"
                ) {{ item.backups[0].log }}

          template(v-slot:item.backupAnteriorInicio="{ item }")
            | {{ new Date(item.backupAnteriorInicio).toLocaleString() }}

          template(
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          )
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                  v-on="on",
                  v-bind="attrs",
                  outlined,
                  width="30",
                  height="30",
                  icon,
                  color="primary",
                  small,
                  @click="expand(!isExpanded); closePanels(isExpanded)"
                )
                  v-icon mdi-history
              span Histórico de backups

          template(
            v-slot:expanded-item="{ headers, item, expand, isExpanded }"
          )
            td(:colspan="headers.length")
              v-expansion-panels.py-3(v-if="expansionPanel")
              
                v-expansion-panel(@click="getBackupSize(item.idcliente)")
                  v-expansion-panel-header Tamanho do backup
                  v-expansion-panel-content
                    v-divider
                    v-row.pa-2.mx-10.justify-space-between(no-gutters)
                      span(v-for="(item, i) in totalBackupSizes", :key="i") {{ bytesToMegabytes(item.totalSize) }}
                    v-row.pa-1(no-gutters, align="center", justify="center")
                      BackupSizeChart(:chartData="backupChartData" v-if="backupChartData.length > 0")
                      v-col.text-center(cols="12", md="12", v-else)
                        span Não há dados para exibir
            
                v-expansion-panel(@click="getHistoryStorage(item.idcliente)")
                  v-expansion-panel-header Tamanho do storage
                  v-expansion-panel-content
                    v-divider
                    v-row.pa-2.text-center(no-gutters)
                      v-col(v-for="(item, i) in historyChartData", :key="i")
                        span {{ bytesToMegabytes(item.tamanho) }}
                    v-row.pa-1(no-gutters, align="center")
                      v-sparkline(
                        v-if="historyChartData.length > 0",
                        fill,
                        auto-draw,
                        height="50",
                        label-size="2",
                        padding="6",
                        :auto-draw-duration="1000",
                        :value="historyChartData.map((item) => item.tamanho)",
                        :labels="historyChartData.map((item) => new Date(item.data).toLocaleDateString())",
                        :smooth="false",
                        :gradient="['#ff5959', '#ffd200', '#1feaea']"
                      )
                      v-col.text-center(cols="12", md="12", v-else)
                        span Não há dados para exibir

                v-expansion-panel(
                  @click="getHistoryChart('diario', item.idcliente, new Date())"
                )
                  v-expansion-panel-header Histórico de backups
                  v-expansion-panel-content
                    v-divider
                    v-row.pa-1(no-gutters, align="center", justify="center")
                      v-col(cols="12", md="4")
                        v-row(no-gutters, justify="center")
                          v-btn-toggle(
                            v-model="toggle_date",
                            mandatory,
                            color="primary",
                            group
                          )
                            v-btn(
                              @click="getHistoryChart('diario', item.idcliente, new Date())"
                            )
                              v-icon(left) mdi mdi-calendar
                              | Diário

                            v-btn(
                              @click="getHistoryChart('semanal', item.idcliente, new Date())"
                            )
                              v-icon(left) mdi mdi-calendar-week
                              | Semanal

                            v-btn(
                              @click="getHistoryChart('mensal', item.idcliente, new Date())"
                            )
                              v-icon(left) mdi mdi-calendar-month
                              | Mensal

                    v-row.pa-1(
                      no-gutters,
                      align="center",
                      justify="space-between"
                    )
                      v-col(cols="12", md="4")
                        v-tooltip(right)
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(
                              v-on="on",
                              v-bind="attrs",
                              color="primary",
                              fab,
                              small,
                              @click="setHistoryChartFowardDate()"
                            )
                              v-icon fa fa-chevron-left
                          span Anterior

                      v-col(
                        cols="12",
                        md="4",
                        align="center",
                        justify="space-between"
                      )
                        h3 {{ chartOptions.visualizationType }} - {{ chartOptions.visualizationPeriod }}

                      v-col.text-right(cols="12", md="4")
                        v-tooltip(left)
                          template(v-slot:activator="{ on, attrs }")
                            v-btn(
                              v-on="on",
                              v-bind="attrs",
                              color="primary",
                              fab,
                              small,
                              @click="setHistoryChartNextDate()"
                            )
                              v-icon fa fa-chevron-right
                          span Próximo
                    #chart.chart



  v-dialog(
    v-if="historyShowDetail",
    v-model="historyShowDetail",
    max-width="1000px",
    width="100%"
  )
    DadosCardWidget(
      :title="`Dados do backup - ${historyBackupDetail.cliente.id} - ${historyBackupDetail.cliente.nome}`",
      :dataset="historyBackupDetail",
      @close="historyShowDetail = false"
    )
</template>

<script>
import csapi from "@/api/csapi";
import { mapMutations } from "vuex";
import ApexCharts from "apexcharts";
import { socket, state } from "@/socket";
import DadosCardWidget from "./DadosCardWidget";

export default {
  components: { DadosCardWidget },
  data() {
    return {
      toggle_date: 0,
      footprops: {
        itemsPerPageOptions: [50, 100, 200, 300, 500, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageAllText: "Todos",
        itemsPerPageText: "Itens por página"
      },
      expansionPanel: false,
      filters: { includeSearch: true, items: [] },
      expandedHistory: [],
      dataHeaders: [
        { text: "Id", value: "idweb", width: "65" },
        { text: "Cliente", value: "nomecliente" },
        {
          text: "Ativo",
          value: "status",
          width: "60",
          align: "center",
          sortable: false
        },
        {
          text: "Status CS Server",
          value: "statusserver",
          width: "110",
          align: "center",
          sortable: false
        },
        {
          text: "Tamanho do Backup",
          value: "tamanhobackup",
          width: "115",
          align: "center",
          sortable: false
        },
        {
          text: "Tamanho do Storage",
          value: "tamanhobucket",
          width: "115",
          align: "center",
          sortable: false
        },
        {
          text: "Backup Anterior",
          value: "backupAnterior",
          width: "390",
          sortable: true
        },
        {
          text: "Backup Atual",
          value: "backupAtual",
          width: "390",
          sortable: true
        },
        {
          text: "Ações",
          value: "data-table-expand",
          width: "60",
          align: "center",
          sortable: false
        }
      ],
      dataStatus: [],
      backupChartData: [],
      totalBackupSizes: [],
      historyChartData: [],
      historyShowDetail: false,
      historyBackupDetail: null,
      visualizationType: "diario",
      visualizationId: null,
      visualizationSelectedDate: new Date(),
      visualizationIsLoading: false,
      chartOptions: {
        type: "",
        visualizationType: "",
        visualizationPeriod: "",
        chartLoading: "",
        series: [],
        categories: [],
        colors: [],
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          distributed: false,
          style: {
            fontSize: "13px",
            fontWeight: 900
          },
          background: {
            enabled: false
          }
        },
        plotOptionsDataLabels: null,
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              position: "center",
              orientation: "horizontal"
            }
          }
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          onDatasetHover: {
            highlightDataSeries: true
          },
          zoom: {
            enabled: false
          }
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          followCursor: false,
          marker: { show: false }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        xaxis: {
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        },
        events: null
      },
      chartActive: null
    };
  },

  watch: {
    socketConnections() {}
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    closePanels(expanded) {
      if (!expanded) {
        this.expansionPanel = false;
        setTimeout(() => {
          this.expansionPanel = true;
        }, 100);
      }
    },

    bytesToMegabytes(bytes) {
      if (bytes === 0) return "0 Bytes";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));

      const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);

      return `${formattedSize} ${sizes[i]}`;
    },

    getColorStatus(status) {
      const statusColorMap = {
        ERRO: "error",
        CONCLUIDO: "success",
        EM_EXECUCAO: "info",
        TRANSFERINDO: "info"
      };
      return statusColorMap[status];
    },

    async fetchData(url, errorMessage) {
      try {
        const res = await csapi.get(url);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      }
    },

    async getClientes() {
      this.showLoader("Carregando clientes...");
      const dataStatus = await this.fetchData(
        "/sysafra/v1/sysbackup/historico/clientes",
        "Erro ao carregar clientes"
      );

      dataStatus.forEach(item => {
        item.backups = item.backups !== null ? item.backups : [];
        item.status = false;
        if (item.config) item.status = item.config.deletadoem !== null ? -1 : item.config.ativo;
        item.backupAnterior = item.backups.length > 1 ? item.backups[1].datahorainicio : null;
        item.backupAtual = item.backups.length > 0 ? item.backups[0].datahorainicio : null;
        item.statusserver = false;
        item.tamanhobucket = this.bytesToMegabytes(item.tamanhobucket);
        // delete item.config;

        if (item.tamanhoatuallog !== undefined && item.tamanhoatualnormal !== undefined) {
          item.tamanhobackup = this.bytesToMegabytes(
            item.tamanhoatuallog + item.tamanhoatualnormal
          );
        }
      });

      dataStatus.sort((a, b) => {
        if (a.status > b.status) return -1;
        if (a.status < b.status) return 1;
        return 0;
      });

      this.dataStatus = dataStatus;
      this.setFilters();
      this.hideLoader();
    },

    async getHistoryStorage(id) {
      this.historyChartData = [];
      const data = await this.fetchData(
        `/sysafra/v1/sysbackup/historico/tamanho-bucket?cliente=${id}`,
        "Erro ao carregar dados do historico do storage"
      );
      if (data) this.historyChartData = data.reverse();
    },

    async getBackupSize(id) {
      this.totalBackupSizes = [];
      this.backupChartData = [];

      const data = await this.fetchData(
        `/sysafra/v1/sysbackup/historico/tamanho-arquivos?cliente=${id}`,
        "Erro ao carregar dados do historico do backup"
      );

      const formatDate = date => new Date(date).toLocaleDateString("pt-BR");

      if (data.length > 0) {
        this.backupChartData = [
          {
            name: "Log",
            data: data.map(entry => ({
              x: formatDate(entry.datahora),
              y: entry.tamanhoatualbackup
            }))
          },
          {
            name: "Normal",
            data: data.map(entry => ({
              x: formatDate(entry.datahora),
              y: entry.tamanhoatualnormal
            }))
          }
        ];

        this.totalBackupSizes = data.map(entry => ({
          date: formatDate(entry.datahora),
          totalSize: entry.tamanhoatualbackup + entry.tamanhoatualnormal
        }));
      } else {
        return (this.backupChartData = []);
      }
    },

    async getDetailsBackup(hash) {
      const data = await this.fetchData(
        `/sysafra/v1/sysbackup/item/${hash}?include-details=true`,
        "Erro ao carregar dados do historico do storage"
      );
      if (data) {
        if (data.logs !== null) data.logs.reverse();
        data.validacao.forEach(item => {
          item.logs.reverse();
        });

        data.datahorainicio = new Date(data.datahorainicio).toLocaleString();
        data.datahorafim = new Date(data.datahorafim).toLocaleString();

        this.historyBackupDetail = data;
        this.historyShowDetail = true;
      }
    },

    setStatus(s) {
      const statusMap = {
        "-1": { t: -1, x: "Removidos", color: "deep-orange lighten-3" },
        0: { t: 0, x: "Inativos", color: "grey lighten-1" },
        1: { t: 1, x: "Ativos", color: "success" }
      };
      return statusMap[s] || statusMap[0];
    },

    setFilters() {
      this.filters.items.length = 0;

      try {
        this.filters.items.push({
          text: "Cliente",
          type: "select",
          values: this.dataStatus.map(item => ({
            field: "idcliente",
            text: item.nomecliente,
            value: item.idcliente
          }))
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push({
          text: "Ativo",
          type: "select",
          values: this.dataStatus.map(item => ({
            field: "status",
            text: this.setStatus(item.status).x,
            value: this.setStatus(item.status).t
          }))
        });
      } catch (error) {
        console.error(error);
      }

      try {
        this.filters.items.push({
          text: "Status CS Server",
          type: "select",
          values: [
            { field: "statusserver", text: "Ativo", value: true },
            { field: "statusserver", text: "Inativo", value: false }
          ]
        });
      } catch (error) {
        console.error(error);
      }
    },

    updateVisualizationSelectedDate(multiplier) {
      const timeIncrements = {
        diario: 86400000, // 1 day in Milliseconds
        semanal: 604800000, // 7 days in Milliseconds
        mensal: 2629800000 + 86400000 // 1 month in Milliseconds
      };

      const increment = timeIncrements[this.visualizationType];
      if (increment) {
        this.visualizationSelectedDate.setTime(
          this.visualizationSelectedDate.getTime() + multiplier * increment
        );
      }
    },

    setHistoryChartNextDate() {
      this.updateVisualizationSelectedDate(1);
      this.getHistoryChart(
        this.visualizationType,
        this.visualizationId,
        this.visualizationSelectedDate
      );
    },

    setHistoryChartFowardDate() {
      this.updateVisualizationSelectedDate(-1);
      this.getHistoryChart(
        this.visualizationType,
        this.visualizationId,
        this.visualizationSelectedDate
      );
    },

    async getHistoryChart(visualizationType, id, selectDate) {
      let data = [];
      this.chartOptions.chartLoading = visualizationType;
      this.chartOptions.type = visualizationType;
      this.visualizationIsLoading = true;

      let startDate = selectDate;
      let endDate = new Date();

      this.visualizationSelectedDate = selectDate;
      startDate.setDate(startDate.getDate());
      endDate.setDate(startDate.getDate());

      // get date - 1
      if (visualizationType === "diario") {
        startDate.setDate(startDate.getDate());
        endDate.setDate(endDate.getDate());
        this.chartOptions.visualizationType = "Diário";
        this.chartOptions.visualizationPeriod = `${startDate.toLocaleDateString()}`;
      } else if (visualizationType === "semanal") {
        const day = new Date(startDate).getDay();
        startDate.setTime(startDate.getTime() - day * 86400000);
        endDate.setTime(startDate.getTime() + 518400000);
        this.chartOptions.visualizationType = "Semanal";
        this.chartOptions.visualizationPeriod = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      } else if (visualizationType === "mensal") {
        const date = new Date(startDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        startDate = new Date(year, month, 1);
        endDate = new Date(year, month + 1, 0);
        this.chartOptions.visualizationType = "Mensal";
        this.chartOptions.visualizationPeriod = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      }

      startDate = startDate
        .toLocaleDateString()
        .split("/")
        .join("-");
      endDate =
        visualizationType === "diario"
          ? startDate
          : endDate
              .toLocaleDateString()
              .split("/")
              .join("-");

      this.visualizationId = id;
      this.visualizationType = visualizationType;

      try {
        const res = await csapi.get(
          `/sysafra/v1/sysbackup/historico/backups?cliente=${id}&periodo=${startDate},${endDate}&tipo=${visualizationType}`
        );
        if (res.status === 200) data = res.data.reverse();
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao carregar dados do histórico do backup!");
        return;
      }

      new Promise(resolve => {
        setTimeout(async () => {
          const doc = document.getElementById("chart");
          if (doc) {
            doc.innerHTML = "";

            /**  Diario */
            if (visualizationType === "diario") {
              this.chartOptions.visualizationType = "Diário";

              data.forEach(item => {
                item.datahorainicio = new Date(item.datahorainicio).toLocaleString();
                item.datahorafim = new Date(item.datahorafim).toLocaleString();
                item.statusNum = item.status.toLowerCase() === "concluido" ? 2 : 1;
                const tempDate = item.datahorainicio.split(", ");
                item.hora = new Date(
                  `${tempDate[0]
                    .split("/")
                    .reverse()
                    .join("-")} ${tempDate[1]}`
                ).getHours();
                item.hora = item.hora < 10 ? `0${item.hora}:00` : `${item.hora}:00`;
              });

              this.chartOptions.categories = data.map(item => item.hora);

              this.chartOptions.colors = [
                function({ value, seriesIndex, w }) {
                  return value === 1 ? "#ff5959" : "#53b257";
                }
              ];

              this.chartOptions.dataLabels.formatter = (val, opt) => {
                let text = val === 1 ? "Falha" : "Sucesso";

                if (data[opt.dataPointIndex].validacao !== undefined) {
                  const x = data[opt.dataPointIndex].validacao;
                  for (let j = 0; j < x.length; j++) {
                    if (x[j].status.toLowerCase() === "finalizado") {
                      text += " ✔️";
                    } else {
                      text += " ❌";
                    }
                  }
                }

                return text;
              };

              this.chartOptions.tooltip.y = {
                formatter(val, opt) {
                  const text = val === 1 ? "Falha" : "Sucesso";
                  return text;
                }
              };

              this.chartOptions.plotOptionsDataLabels = {
                total: { enabled: false }
              };

              const numerosSerie = data.map(item => item.statusNum);

              this.chartOptions.series = [{ name: "Backup", data: numerosSerie }];

              this.chartOptions.events = {
                dataPointSelection: (event, chartContext, config) => {
                  const temp = data[config.dataPointIndex];

                  if (temp.logs !== null) temp.logs.reverse();
                  temp.validacao.forEach(item => {
                    item.logs.reverse();
                  });

                  this.historyBackupDetail = temp;
                  this.historyShowDetail = true;
                }
              };

              this.toggle_date = 0;
            } else {
              /**  Senmanal & Mensal */
              if (visualizationType == "mensal") {
                data.sort((a, b) => {
                  if (a.data < b.data) return -1;
                  if (a.data > b.data) return 1;
                  return 0;
                });
              }

              this.chartOptions.visualizationType =
                visualizationType === "semanal" ? "Semanal" : "Mensal";
              this.chartOptions.visualizationPeriod = `${startDate
                .split("-")
                .join("/")} - ${endDate.split("-").join("/")}`;

              if (visualizationType === "semanal") {
                this.chartOptions.categories = data.map(item => {
                  const date = item.data.split("-");
                  return new Date(date[2], date[1] - 1, date[0]).toLocaleDateString();
                });
              } else {
                this.chartOptions.categories = data.map(item => ` Semana ${item.data}`);
              }

              this.chartOptions.colors = ["#53b257", "#ff5959"];

              this.chartOptions.series = [
                {
                  name: "Sucesso",
                  data: data.map(item => item.sucesso)
                },
                {
                  name: "Erro",
                  data: data.map(item => item.falha)
                }
              ];

              this.chartOptions.dataLabels.formatter = function(val, opt) {
                return val === 0 ? "" : val;
              };

              this.chartOptions.tooltip.y = {
                formatter(val, opt) {
                  const { seriesIndex } = opt;
                  return seriesIndex === 1
                    ? `${val} backup(s) com falha(s)`
                    : `${val} backups(s) finalizados com sucesso`;
                }
              };

              if (visualizationType === "semanal") {
                this.chartOptions.events = {
                  dataPointSelection: (event, chartContext, config) => {
                    this.toggle_date = 0;

                    const pos = config.dataPointIndex;
                    const newDataSelected = new Date(
                      data[pos].data
                        .split("-")
                        .reverse()
                        .join("-")
                    );
                    this.visualizationSelectedDate = newDataSelected;
                    this.getHistoryChart("diario", id, newDataSelected);
                  }
                };
              } else {
                this.chartOptions.events = {
                  dataPointSelection: (event, chartContext, config) => {
                    this.toggle_date = 1;

                    const week = data[config.dataPointIndex].data - 1;
                    const primaryDay = new Date(
                      this.visualizationSelectedDate.getFullYear(),
                      this.visualizationSelectedDate.getMonth(),
                      week * 7
                    );
                    const currentDayOfWeek = primaryDay.getDay(); // 0 (Domingo) a 6 (Sábado)
                    const startOfWeek = new Date(primaryDay);
                    const endOfWeek = new Date(primaryDay);

                    // Calcula o dia inicial da semana (Domingo)
                    startOfWeek.setDate(primaryDay.getDate() - currentDayOfWeek);

                    // Calcula o dia final da semana (Sábado)
                    endOfWeek.setDate(primaryDay.getDate() + (6 - currentDayOfWeek));

                    this.getHistoryChart("semanal", id, startOfWeek);
                  }
                };
              }
            }

            this.chartOptions.chart.events = this.chartOptions.events;
            this.chartOptions.xaxis.categories = this.chartOptions.categories;

            this.visualizationIsLoading = false;

            const chart = new ApexCharts(document.getElementById("chart"), this.chartOptions);
            chart.render();
          }
          resolve();
        }, 1);
      }).finally(() => {
        this.chartOptions.chartLoading = "";
      });
    },

    atualizaData(data) {
      data.forEach(item => {
        item.backups = item.backups !== null ? item.backups : [];
        item.status = false;
        if (item.config) item.status = item.config.deletadoem !== null ? -1 : item.config.ativo;
        // delete item.config;
      });

      data.sort((a, b) => {
        if (a.status > b.status) return -1;
        if (a.status < b.status) return 1;
        return 0;
      });

      data.forEach(item => {
        const index = this.dataStatus.findIndex(row => item.idcliente === row.idcliente);
        if (index === -1) {
          this.dataStatus.push(item);
        } else {
          this.dataStatus[index].backups = item.backups;
        }
      });
    }
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  computed: {
    socketConnections() {
      this.dataStatus.forEach(item => {
        const index = state.socketConnections.findIndex(row => item.hash === row.data.token.hash);
        item.statusserver = index != -1;
      });

      return state.socketConnections;
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    this.isControl(false);
    this.setTitle("SySBackup - Monitor");
    this.getClientes();

    socket.on("sysafra:sysbackup:historico:monitor", data => {
      this.atualizaData(data);
    });
  },

  beforeDestroy() {
    socket.off("sysafra:sysbackup:historico:monitor");
  }
};
</script>

<style>
.monitorTable .v-data-table__wrapper {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.clientesLogsTable .v-data-table__wrapper {
  height: auto;
  overflow-y: auto;
}
</style>
